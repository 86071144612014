<template>
  <div>
    <px-capa :banner-capa="header" :show-action-btn="!isLogged">
      <template v-slot:title>
        Compre os suprimentos que sua empresa precisa
      </template>
      <template v-slot:text>
        Compre todos os suprimentos para sua empresa em um único lugar e em poucos cliques
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-departamentos-row query="id in (23, 34, 24, 17) and ativo is true"/>
      <px-facilidades/>
      <px-home-page-block
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="30"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-home-page-block
        show-link
        link="/q/d/24"
        class="mt-12"
        :query="queryMateriaisEscritorio"
        title="Materiais de Escritório"
        :qtd-ofertas="30"/>
      <v-row dense no-gutters class="mt-5">
        <v-col cols="6" class="d-flex align-center px-2"
               v-for="(banner, index) in bannersDuplos" :key="index">
          <px-home-banner-card :title="banner.titulo"
                               :link="banner.link"
                               :banner-image="banner.foto"
                               gradient="none"
                               :max-height="height"
                               :min-height="height"/>
        </v-col>
      </v-row>
      <px-home-page-block
        show-link
        link="/q/d/17"
        class="mt-10"
        :query="queryHigiene"
        title="Limpeza, Higiene e Descartáveis"
        :qtd-ofertas="20"/>
      <px-home-page-block
        show-link
        link="/q/d/23"
        class="mt-10"
        :query="queryEmbalagens"
        title="Embalagens e Caixas"
        :qtd-ofertas="20"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                text-btn="Quero me cadastrar"
                                @click="onClickBannerCadastro"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import HomeBannerCard from '@/modules/home-page/HomeBannerCard.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import RdDuvidaFormSubscription from '@/modules/integration/rd/RdDuvidaFormSubscription.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import Facilidades from '@/modules/institucional/Facilidades.vue';
import { mapGetters } from 'vuex';
import DepartamentosRow from '@/modules/home-page/DepartamentosRow.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import header from '../../assets/landing-page/suprimentos/header-suprimentos.png';
import bannerDuploVassouras from '../../assets/landing-page/suprimentos/vassouras.png';
import bannerDuploVassourasMobile from '../../assets/landing-page/suprimentos/vassouras-mobile.png';
import bannerDuploCopos from '../../assets/landing-page/suprimentos/copos.png';
import bannerDuploCoposMobile from '../../assets/landing-page/suprimentos/copos-mobile.png';
import promoPapalA4 from '../../assets/landing-page/suprimentos/papel.png';
import promoPapalA4Mobile from '../../assets/landing-page/suprimentos/papel-mobile.png';
import promoAgua from '../../assets/landing-page/suprimentos/agua-sanitaria.png';
import promoAguaMobile from '../../assets/landing-page/suprimentos/agua-sanitaria-mobile.png';
import promoRolao from '../../assets/landing-page/suprimentos/papel-hig.png';
import promoRolaoMobile from '../../assets/landing-page/suprimentos/papel-hig-mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and departamento.id in (17, 23, 24, 34) order by random',
      queryMateriaisEscritorio: 'departamento.id = 24 and disponivel is true order by relevancia',
      queryHigiene: 'departamento.id = 17 and disponivel is true order by random',
      queryEmbalagens: 'departamento.id = 23 and disponivel is true order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os materiais de escritório para sua empresa',
      banners: [],
      bannersDuplos: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    height() {
      if (this.isMobile) {
        return '300px';
      }
      return '360px';
    },
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(promoPapalA4, promoPapalA4Mobile),
        link: '/oferta/18447',
      },
      {
        foto: this.getGridImg(promoAgua, promoAguaMobile),
        link: '/oferta/11455',
      },
      {
        foto: this.getGridImg(promoRolao, promoRolaoMobile),
        link: '/oferta/11414',
      },
    ];
    this.bannersDuplos = [
      {
        foto: this.getGridImg(bannerDuploVassouras, bannerDuploVassourasMobile),
        link: '/q/c/247',
      },
      {
        foto: this.getGridImg(bannerDuploCopos, bannerDuploCoposMobile),
        link: '/q/c/195',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxHomeBannerCard: HomeBannerCard,
    pxLandingPageBanners: LandingPageBanners,
    pxCapa: Capa,
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
    pxFaqLandingPage: FaqLandingPage,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
    pxFacilidades: Facilidades,
    pxDepartamentosRow: DepartamentosRow,
  },
};
</script>
